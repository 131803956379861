.imgcont{
  border: 2px solid black;
  /* background-image: url("../../assets/bythecover.png"); */
  /* background-size: 100%; */
}

#top{
  /* margin-top: 30%; */
  text-align: center;
}

.listen-button{
    background-color: transparent;
    font-size: 30pt;
    color: white;
    border: 4px solid white;
    margin-bottom: 10px;
    animation: color-change 1s infinite;
}
  
  @keyframes color-change {
    0% { color: rgba(253,187,45,1); }
    50% { color: white; }
    100% { color: rgba(253,187,45,1); }
  }

.listen-button:hover{
    border: 4px solid rgba(253,187,45,1);

}
  

/* @media (max-width: 400px) {
    .listen-button{
        border: 2px solid white;
        font-size: 10pt;
        position: absolute;
        margin: -50% 0% 0% 47%;
        
    }
    .listen-button:hover{
        border: 2px solid black;
        color: black;
    }
  }

  @media all and (max-width: 370px) and (min-width: 320px) {
    .listen-button{
        border: 2px solid white;
        font-size: 9pt;
        position: absolute;
        margin: -53% 0% 0% 45%;
        
    }
    .listen-button:hover{
        border: 2px solid black;
        color: black;
    }
  }

  @media all and (max-width: 400px) and (min-width: 375px) {
    .listen-button{
        border: 2px solid white;
        font-size: 9pt;
        position: absolute;
        margin: -53% 0% 0% 49.5%;
        
    }
    .listen-button:hover{
        border: 2px solid black;
        color: black;
    }
  }

  @media all and (max-width: 500px) and (min-width: 411px) {
    .listen-button{
        border: 2px solid white;
        font-size: 11pt;
        position: absolute;
        margin: -53% 0% 0% 49.5%;
        
    }
    .listen-button:hover{
        border: 2px solid black;
        color: black;
    }
  }

  @media all and (max-width: 1000px) and (min-width: 700px) {
    .listen-button{
        border: 2px solid white;
        font-size: 20pt;
        position: absolute;
        margin: -53% 0% 0% 52%;
        
    }
    .listen-button:hover{
        border: 2px solid black;
        color: black;
    }
  }

  @media all and (max-width: 1050px) and (min-width: 1024px) {
    .listen-button{
        border: 2px solid white;
        font-size: 27pt;
        position: absolute;
        margin: -53% 0% 0% 53%;
        
    }
    .listen-button:hover{
        border: 2px solid black;
        color: black;
    }
  }

  @media all and (max-width: 630px) and (min-width: 560px) and (orientation: landscape){
    .listen-button{
      border: 2px solid white;
      font-size: 15pt;
      position: absolute;
      margin: -53% 0% 0% 48%;
      
  }
  .listen-button:hover{
      border: 2px solid black;
      color: black;
  }
  }

  @media all and (max-width: 650px) and (min-width: 640px) and (orientation: landscape){
    .listen-button{
      border: 2px solid white;
      font-size: 18pt;
      position: absolute;
      margin: -55% 0% 0% 49%;
      
  }
  .listen-button:hover{
      border: 2px solid black;
      color: black;
  }
  }

  @media all and (max-width: 720px) and (min-width: 660px) and (orientation: landscape){
    .listen-button{
      border: 2px solid white;
      font-size: 19pt;
      position: absolute;
      margin: -53% 0% 0% 49%;
      
  }
  .listen-button:hover{
      border: 2px solid black;
      color: black;
  }
  }

  @media all and (max-width: 790px) and (min-width: 730px) and (orientation: landscape){
    .listen-button{
      border: 2px solid white;
      font-size: 19pt;
      position: absolute;
      margin: -53% 0% 0% 50%;
      
  }
  .listen-button:hover{
      border: 2px solid black;
      color: black;
  }
  }

  @media all and (max-width: 1000px) and (min-width: 800px) and (orientation: landscape){
    .listen-button{
      border: 2px solid white;
      font-size: 20pt;
      position: absolute;
      margin: -53% 0% 0% 48%;
      
  }
  .listen-button:hover{
      border: 2px solid black;
      color: black;
  }
  }

  @media all and (max-width: 1400px) and (min-width: 1300px) and (orientation: landscape){
    .listen-button{
      border: 2px solid white;
      font-size: 35pt;
      position: absolute;
      margin: -53% 0% 0% 53%;
      
  }
  .listen-button:hover{
      border: 2px solid black;
      color: black;
  }
  } */