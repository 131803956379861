.imgcont{
    border: 2px solid black;
    overflow: hidden;  
}

.img{
width: 100%;
margin-top: 5%;
}

.img:hover {
        transform: scale(1.1);
        transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
      }


      @media screen and (max-width: 600px) {
        .img{
          margin-top: 21%;
          }
      }
      
      @media screen and (max-width: 480px) {
        .img{
          margin-top: 21%;
          }
      }