#border{
    border-bottom: 3px solid black;

}

.color{
    background: black;

}
.logo{
    height: 65px;
    /* margin-top: ; */
    margin-bottom: -.2%;

}

#sc{
    color: rgb(226, 224, 224);
    font-size: 40px;
}

#fb{
    margin: 0px 0px 0px 25px;
    color: rgb(226, 224, 224);
    font-size: 40px;
}
#twitter{
    margin: 0px 0px 0px 25px;
    color: rgb(226, 224, 224);
    font-size: 40px;
}
#insta{
   margin: 0px 0px 0px 25px;
   color: rgb(226, 224, 224);
   font-size: 40px;
}

#sc:hover{
    color: #ff7700;
}
#fb:hover{
    color: royalblue;
}
#twitter:hover{
    color: #1DA1F2;
}
#insta:hover{
    color:  rgba(252,70,107,1);
}



@media (min-width: 300px) and (max-width: 320px) { 
    #sc{
        color: rgb(226, 224, 224);
        font-size: 30px;
    }
    
    #fb{
        margin: 0px 0px 0px 15px;
        color: rgb(226, 224, 224);
        font-size: 30px;
    }
    #twitter{
        margin: 0px 0px 0px 15px;
        color: rgb(226, 224, 224);
        font-size: 30px;
    }
    #insta{
       margin: 0px 0px 0px 15px;
       color: rgb(226, 224, 224);
       font-size: 30px;
    }
 }

 @media (min-width: 330px) and (max-width: 375px) { 
    #sc{
        color: rgb(226, 224, 224);
        font-size: 35px;
    }
    
    #fb{
        margin: 0px 0px 0px 15px;
        color: rgb(226, 224, 224);
        font-size: 35px;
    }
    #twitter{
        margin: 0px 0px 0px 15px;
        color: rgb(226, 224, 224);
        font-size: 35px;
    }
    #insta{
       margin: 0px 0px 0px 15px;
       color: rgb(226, 224, 224);
       font-size: 35px;
    }
 }
