.gal{
    border: 2px solid black;
    /* background-image: url("../../assets/gallary.jpg"); */
    /* background-size: 100%; */
    background: black;

}

.pic{
    margin-top: 15%;
}