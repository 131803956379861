.ae-logo-footer{
    height: 100px;
    
}

.footer{
    border-top: 5px solid black;
}
 .copy{
     margin-top: 1%;
 }

 /* @media (max-width: 400px) {
    .footer{
        border-top: 2px solid black;
    }
    .ae-logo-footer{
        height: 75px;
        
    }
  
  } */